import React from "react";

import "./AboutArtistStyles.css";

export default function AboutArtist() {
  return (
    <div className="aboutartist-wrap">
      <h1>About me and achievements</h1>
      <p>
        For the first time, I saw painted flowers on the walls of a clay house
        in my grandmother's village in Petrykivka, Dnipropetrovsk region when I
        was 10 years old. Bright flowers, birds and amazing plots captured my
        childhood imagination.
      </p>
      <p>
        The Ukrainian tradition of painting the walls of a peasant's house
        existed as early as 300 years ago. Many generations of nugget masters
        from Petrykyvka carefully passed down their skills from grandmother to
        granddaughter, preserving the traditions and sacred meanings of the
        patterns. No one in my family drew. But my father always supported my
        creativity. So, thanks to him, I became more familiar with the special
        Ukrainian ethnic painting.
      </p>
      <p>
        The Druzhba factory worked in the village of Petrikivka for 40 years, so
        my father sent me to study with the famous artist Fyodor Panko. And it
        was in the workshops of the factory that I saw an unusual way of
        painting berries and flowers with my fingers. The brushes were also
        homemade from cat fur and were called "kittyka". At the factory,
        painters painted bright wooden caskets, plates, spoons, chests, etc. in
        huge batches. Therefore, almost every Ukrainian person has an idea about
        the Petrykiv painting. I was lucky to see all technological processes
        and learn to distinguish Ukrainian painting from others thanks to the
        technique and symbolism that I learned in childhood.
      </p>
      <p>
        Already at a mature age, I remembered the ability to draw Petrykiv
        painting. Over time, I created a creative studio "Zernyatko" in Lviv.
        And my goal became to reproduce the flowering of Petrykivka that I once
        saw in my childhood. And now, for 12 years, my mission has been to
        master and popularize the Petrykiv painting. I teach children and adults
        and continue to study myself.
      </p>
      <p>
        Now I use finger painting as art therapy. And drawing small elements
        with a "cat" in order to master Ukrainian ethnic calligraphy. All this
        well develops fine motor skills in children and forms creative thinking.
      </p>
      <p>
        To date, I have many exhibitions in Ukraine and 16 personal exhibitions
        abroad. Namely in Poland, Spain, France and Turkey.
      </p>
      <p>
        Also, I work as an art psychologist in Lviv and abroad. Currently, I am
        working on my 2 manuals about Petrikovsky painting. In addition, several
        methods with the basics of painting and psychological influence on the
        development of children's motor skills are being refined. The manuals
        are intended for the work of teachers and psychologists with painting as
        art therapy. But for people who want to master Petrykiv painting without
        a teacher.
      </p>
      <p>
        For almost 350 years of the existence of folk wall painting, the
        Petrykiv painting has turned into an art that has been recognized as an
        intangible heritage of "UNESCO". The symbolism and skill of artists is
        enriched. But the tools and techniques of painting still remain
        primitive, which distinguishes Petrykiv painting from other types of
        applied art.
      </p>
    </div>
  );
}
